import React from 'react';
import { useTranslation } from 'react-i18next';
import { Client } from '../../../types/types';
import ClientInformations from './informations/ClientInformations';
import ClientBody from './body/ClientBody';
import ClientGoals from './goals/ClientGoals';
import ClientTrainings from './trainings/ClientTrainings';
import DeleteClient from './informations/DeleteClient';
import Collapsible from 'react-collapsible';

interface ClientDetailsProps {
  client: Client;
  onClientDeleted: () => void; // Dodajemy nową właściwość
}

const ClientDetails: React.FC<ClientDetailsProps> = ({ client, onClientDeleted }) => {
  const { t } = useTranslation('global');
  return (
    <div>
      <Collapsible
        trigger={
          <div className="collapsible-element">
            <span>{t("clients.informations")}</span>
          </div>
        }
        open={true}
      >
        <ClientInformations client={client} />
        <DeleteClient client={client} onClientDeleted={onClientDeleted} />
      </Collapsible>

      <Collapsible
        trigger={
          <div className="collapsible-element">
            <span>{t("clients.body")}</span>
          </div>
        }
        open={false}
      >
        <ClientBody client={client} />
      </Collapsible>

      <Collapsible
        trigger={
          <div className="collapsible-element">
            <span>{t("clients.goals")}</span>
          </div>
        }
        open={false}
      >
        <ClientGoals client={client} />
      </Collapsible>

      <Collapsible
        trigger={
          <div className="collapsible-element">
            <span>{t("clients.trainings")}</span>
          </div>
        }
        open={false}
      >
        <ClientTrainings client={client} />
      </Collapsible>
    </div>
  );
};

export default ClientDetails;
