import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Client, ClientBodyTest } from '../../../../types/types';
import AddNewBodyTest from './AddNewBodyTest';
import AddClientBodyTest from './AddClientBodyTest';
import GetClientBodyTest from './GetClientBodyTest';
import Modal from '../../../../constants/Modal';

interface ClientDetailsProps {
  client: Client;
}

const ClientBodyTests: React.FC<ClientDetailsProps> = ({ client }) => {
  const { t } = useTranslation('global');
  const [bodyTests, setBodyTests] = useState<ClientBodyTest[]>([]);
  const [showModalNewTest, setShowModalNewTest] = useState<boolean>(false);
  const [showModalClientTest, setShowModalClientTest] = useState<boolean>(false);

  return (
    <div>
      <GetClientBodyTest client={client} bodyTests={bodyTests} setBodyTests={setBodyTests} />

      <button onClick={() => setShowModalNewTest(true)}>{t('buttons.create_new_test')}</button>
      <button onClick={() => setShowModalClientTest(true)}>{t('buttons.add_new_test')}</button>

      <Modal show={showModalClientTest} onClose={() => setShowModalClientTest(false)} title={t('buttons.add_new_test')}>
        <AddClientBodyTest client={client} setBodyTests={setBodyTests} />
      </Modal>

      <Modal show={showModalNewTest} onClose={() => setShowModalNewTest(false)} title={t('buttons.create_new_test')}>
        <AddNewBodyTest client={client} onClose={() => setShowModalNewTest(false)} />
      </Modal>
    </div>
  );
};

export default ClientBodyTests;
