import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Client, Goal } from '../../../../types/types';
import AddGoals from './AddGoals';
import GetGoals from './GetGoals';
import GoalsService from '../../../../services/goals/GoalsService';
import Modal from '../../../../constants/Modal';

interface ClientDetailsProps {
  client: Client;
}

const ClientGoals: React.FC<ClientDetailsProps> = ({ client }) => {
  const { t } = useTranslation('global');
  const [goals, setGoals] = useState<Goal[]>([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadGoals();
  }, [client.id]);

  const loadGoals = () => {
    GoalsService.getGoals(client.id)
      .then(response => {
        setGoals(response.data);
      })
      .catch(error => {
        console.error('Error loading goals:', error);
      });
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      <GetGoals goals={goals} />

      <button onClick={handleOpenModal}>
        {t('buttons.add_goal')}
      </button>


      <Modal show={showModal} onClose={handleCloseModal} title={t('buttons.add_goal')}>
        <AddGoals client={client} onGoalAdded={() => {
          loadGoals();
          handleCloseModal();
        }} />
      </Modal>
    </div>
  );
};

export default ClientGoals;
